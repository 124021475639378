export const APP_NAME = "Wise Global";
export const PERSIST_STORE_NAME = "admin";

export const REDIRECT_URL_KEY = "redirectUrl";
export const TOKEN_URL_KEY = "token";
export const EMAIL_URL_KEY = "email";

export const NUMBERS = {
  ONE: 1,
};

export const EVENTS = {
  CLICK: "click",
  HOVER: "hover",
};
