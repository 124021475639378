import React, { useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Button, Dialog } from "components/ui";
import SideNavToggle from "components/SideNavToggle";
import MobileNav from "components/MobileNav";

import useAuth from "utils/hooks/useAuth";
import useResponsive from "utils/hooks/useResponsive";
import { HEADER_HEIGHT_CLASS } from "constants/theme.constant";

import { HiOutlineLogout } from "react-icons/hi";

const Header = ({ headerMiddle, className, container }) => {
  const { signOut } = useAuth();
  const { larger } = useResponsive();

  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const onDialogOpen = () => setIsDialogOpen(true);
  const onDialogClose = () => setIsDialogOpen(false);
  const onDialogConfirm = async () => {
    try {
      setIsLoading(true);

      await signOut();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Dialog
        isOpen={isDialogOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
      >
        <h5>{t("HEADER.LOG_OUT")}</h5>
        <div className="text-right mt-6">
          <Button
            className="ltr:mr-2 rtl:ml-2"
            variant="plain"
            onClick={onDialogClose}
          >
            {t("BUTTONS.NO")}
          </Button>
          <Button variant="solid" loading={isLoading} onClick={onDialogConfirm}>
            {t("BUTTONS.YES")}
          </Button>
        </div>
      </Dialog>

      <header className={classNames("header", className)}>
        <div
          className={classNames(
            "header-wrapper",
            HEADER_HEIGHT_CLASS,
            container && "container mx-auto",
          )}
        >
          <div className="header-action header-action-start text-black">
            <MobileNav />
            <SideNavToggle />
          </div>
          {headerMiddle && (
            <div className="header-action header-action-middle">
              {headerMiddle}
            </div>
          )}
          <div className="header-action header-action-end">
            <div
              className="flex items-center header-action-item header-action-item-hoverable text-black font-semibold"
              onClick={() => onDialogOpen()}
            >
              <span className="text-xl">
                <HiOutlineLogout />
              </span>
              {larger.md && (
                <span className="ml-1">{t("BUTTONS.LOG_OUT")}</span>
              )}
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  headerMiddle: PropTypes.node,
  container: PropTypes.bool,
};

export default Header;
