import { PERMISSIONS, GENERAL_PERMISSIONS } from "constants/user.constant";
import { decodePermissions } from "utils/decodePermissions";

export const usePermission = (permissions, item) => {
  if (!permissions) return true;

  const allPermissions = [
    ...GENERAL_PERMISSIONS,
    ...decodePermissions(PERMISSIONS, permissions),
  ];

  const isAvailablePermission = allPermissions.includes(item);

  return isAvailablePermission;
};
