import React from "react";

import authRoute from "./authRoute";
import { AUTHORITY } from "constants/roles.constant";

const { SUPER_ADMIN, ADMIN } = AUTHORITY;

export const publicRoutes = [...authRoute];

export const protectedRoutes = [
  {
    key: "dashboard",
    path: "/dashboard",
    title: "SIDE_MENU.DASHBOARD",
    component: React.lazy(() => import("views/Dashboard")),
    authority: [SUPER_ADMIN, ADMIN],
  },
  {
    key: "admins",
    path: "/admins",
    title: "SIDE_MENU.ADMINS",
    component: React.lazy(() => import("views/Admins")),
    authority: [SUPER_ADMIN],
  },
  {
    key: "users",
    path: "/users",
    title: "SIDE_MENU.USERS",
    component: React.lazy(() => import("views/Users")),
    authority: [SUPER_ADMIN, ADMIN],
  },
  {
    key: "users",
    path: "/users/user-details/:userId",
    title: "SIDE_MENU.USERS",
    component: React.lazy(() => import("views/UserDetails")),
    authority: [SUPER_ADMIN, ADMIN],
  },
  {
    key: "discipleship",
    path: "/discipleship",
    title: "SIDE_MENU.DISCIPLESHIP",
    component: React.lazy(() => import("views/Discipleship")),
    authority: [SUPER_ADMIN, ADMIN],
  },
  {
    key: "userGroups",
    path: "/user-groups",
    title: "SIDE_MENU.USER_GROUPS",
    component: React.lazy(() => import("views/UserGroups")),
    authority: [SUPER_ADMIN, ADMIN],
  },
  {
    key: "userGroups",
    path: "/user-groups/group-details/:groupId",
    title: "SIDE_MENU.USER_GROUPS",
    component: React.lazy(() => import("views/UserGroupDetails")),
    authority: [SUPER_ADMIN, ADMIN],
  },
  {
    key: "region",
    path: "/region",
    title: "SIDE_MENU.REGION",
    component: React.lazy(() => import("views/Region")),
    authority: [SUPER_ADMIN, ADMIN],
  },
  {
    key: "denomination",
    path: "/denomination",
    title: "SIDE_MENU.DENOMINATION",
    component: React.lazy(() => import("views/Denomination")),
    authority: [SUPER_ADMIN, ADMIN],
  },
  {
    key: "ministryMap",
    path: "/ministry-map",
    title: "SIDE_MENU.MINISTRY_MAP",
    component: React.lazy(() => import("views/MinistryMap")),
    authority: [SUPER_ADMIN, ADMIN],
  },
  {
    key: "getTrained",
    path: "/get-trained",
    title: "SIDE_MENU.GET_TRAINED",
    component: React.lazy(() => import("views/GetTrained")),
    authority: [SUPER_ADMIN, ADMIN],
  },
  {
    key: "getTrained",
    path: "/get-trained/video-category-details/:videoCategoryId",
    title: "SIDE_MENU.GET_TRAINED",
    component: React.lazy(() => import("views/VideoCategoryDetails")),
    authority: [SUPER_ADMIN, ADMIN],
  },
  {
    key: "reports",
    path: "/reports",
    title: "SIDE_MENU.REPORTS",
    component: React.lazy(() => import("views/Reports")),
    authority: [SUPER_ADMIN, ADMIN],
  },
  {
    key: "notifications",
    path: "/notifications",
    title: "SIDE_MENU.NOTIFICATIONS",
    component: React.lazy(() => import("views/Notifications")),
    authority: [SUPER_ADMIN, ADMIN],
  },
  {
    key: "notifications",
    path: "/notifications/details/:notificationId",
    title: "SIDE_MENU.NOTIFICATIONS",
    component: React.lazy(() => import("views/NotificationDetails")),
    authority: [SUPER_ADMIN, ADMIN],
  },
  {
    key: "settings",
    path: "/settings",
    title: "SIDE_MENU.SETTINGS",
    component: React.lazy(() => import("views/Settings")),
    authority: [SUPER_ADMIN, ADMIN],
  },
];
