import { Alert, toast } from "components/ui";
import { STATE_TYPES_MAP } from "constants/statuses.constant";

const options = {
  transitionType: "fade",
  duration: 5000,
};

const className = "w-[800px]";

export class ToastService {
  static success(message) {
    toast.push(
      <Alert
        showIcon
        closable
        type={STATE_TYPES_MAP.SUCCESS}
        rounded={false}
        className={className}
      >
        {message}
      </Alert>,
      { ...options },
    );
  }

  static error(message) {
    toast.push(
      <Alert
        showIcon
        closable
        type={STATE_TYPES_MAP.DANGER}
        rounded={false}
        className={className}
      >
        {message}
      </Alert>,
      { ...options },
    );
  }
}
