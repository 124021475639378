export const TABLE_ITEMS_LIMIT = 10;
export const TABLE_EXTENDED_ITEMS_LIMIT = 12;
export const TABLE_START_PAGE = 1;

export const TABLE_INITIAL_STATE = {
  total: 0,
  pageIndex: TABLE_START_PAGE,
  pageSize: TABLE_ITEMS_LIMIT,
};

export const SORT_ORDERS = {
  ASC: "asc",
  DESC: "desc",
};

export const TABLE_EXTENDED_KEY_INITIAL_STATE = {
  ...TABLE_INITIAL_STATE,
  pageSize: TABLE_EXTENDED_ITEMS_LIMIT,
  query: "",
  sort: {
    order: SORT_ORDERS.DESC,
    key: "JoinedAt",
  },
};

export const TABLE_EXTENDED_INITIAL_STATE = {
  ...TABLE_INITIAL_STATE,
  pageSize: TABLE_EXTENDED_ITEMS_LIMIT,
  query: "",
  sort: {
    order: SORT_ORDERS.DESC,
    key: "",
  },
};

export const TABLE_SECONDARY_INITIAL_STATE = {
  ...TABLE_INITIAL_STATE,
  pageSize: TABLE_ITEMS_LIMIT,
  query: "",
  sort: {
    order: SORT_ORDERS.DESC,
    key: "",
  },
};

export const TABLE_VIDEOS_INITIAL_STATE = {
  ...TABLE_INITIAL_STATE,
  pageSize: TABLE_ITEMS_LIMIT,
  query: "",
  sort: {
    order: "",
    key: "",
  },
};

export const PAGINATION_PAGER_COUNT = 5;
export const PAGINATION_DOUBLE_ARROW_STEP = 2;
export const PAGINATION_MIDDLE_PAGER_COUNT =
  PAGINATION_PAGER_COUNT - PAGINATION_DOUBLE_ARROW_STEP;

export const SELECT_ITEMS_LIMIT = 100;
export const SELECT_INITIAL_OFFSET = 0;

export const ORDER_DIRECTIONS = {
  UP: "Up",
  DOWN: "Down",
};

export const MAX_SYMBOLS = {
  NAME: 15,
  FULL_NAME: 25,
  ADMIN_EMAIL: 35,
  EMAIL: 25,
  DENOMINATION: 20,
  LABEL: 20,
  EXTENDED_LABEL: 30,
  DESCRIPTION: 25,
  EXTENDED_DESCRIPTION: 35,
};
