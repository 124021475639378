export const decodePermissions = (permissions, sumOfPermissions) => {
  const selectedPermissions = [];

  for (let i = 0; i < permissions.length; i++) {
    const permission = permissions[i];

    if (sumOfPermissions & permission.value) {
      selectedPermissions.push(permission.label);
    }
  }

  return selectedPermissions;
};

export const decodePermissionsToObj = (permissions, sumOfPermissions) => {
  const selectedPermissions = [];

  for (let i = 0; i < permissions.length; i++) {
    const permission = permissions[i];

    if (sumOfPermissions & permission.value) {
      selectedPermissions.push({
        value: permission.value,
        label: permission.label,
      });
    }
  }

  return selectedPermissions;
};
