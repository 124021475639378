import React from "react";

const authRoute = [
  {
    key: "signIn",
    path: `/sign-in`,
    component: React.lazy(() => import("views/auth/SignIn")),
    authority: [],
  },
  {
    key: "signUp",
    path: `/sign-up`,
    component: React.lazy(() => import("views/auth/SignUp")),
    authority: [],
  },
  {
    key: "forgotPassword",
    path: `/forgot-password`,
    component: React.lazy(() => import("views/auth/ForgotPassword")),
    authority: [],
  },
  {
    key: "resetPassword",
    path: `/reset-password`,
    component: React.lazy(() => import("views/auth/ResetPassword")),
    authority: [],
  },
  {
    key: "resetPasswordExpired",
    path: `/reset-password-expired`,
    component: React.lazy(() => import("views/auth/ResetPasswordExpired")),
    authority: [],
  },
  {
    key: "resetPasswordLimitExceeded",
    path: `/reset-password-limit-exceeded`,
    component: React.lazy(() =>
      import("views/auth/ResetPasswordLimitExceeded"),
    ),
    authority: [],
  },
  {
    key: "createPasswordAdmin",
    path: `/create-password-admin`,
    component: React.lazy(() => import("views/auth/CreatePasswordAdmin")),
    authority: [],
  },
  {
    key: "createPasswordAdminExpired",
    path: `/create-password-admin-expired`,
    component: React.lazy(() =>
      import("views/auth/CreatePasswordAdminExpired"),
    ),
    authority: [],
  },
  {
    key: "twoFA",
    path: `/2fa`,
    component: React.lazy(() => import("views/auth/TwoFA")),
    authority: [],
  },
  {
    key: "privacyPolicy",
    path: `/privacy-policy`,
    component: React.lazy(() => import("views/PrivacyPolicy")),
    authority: [],
  },
  {
    key: "termsAndConditions",
    path: `/terms-and-conditions`,
    component: React.lazy(() => import("views/TermsAndConditions")),
    authority: [],
  },
  {
    key: "changeEmail",
    path: `/change-email`,
    component: React.lazy(() => import("views/auth/ChangeEmail")),
    authority: [],
  },
  {
    key: "changeEmailExpired",
    path: `/change-email-expired`,
    component: React.lazy(() => import("views/auth/ChangeEmailExpired")),
    authority: [],
  },
  {
    key: "changePassword",
    path: `/change-password`,
    component: React.lazy(() => import("views/auth/ChangePassword")),
    authority: [],
  },
  {
    key: "changePasswordExpired",
    path: `/change-password-expired`,
    component: React.lazy(() => import("views/auth/ChangePasswordExpired")),
    authority: [],
  },
];

export default authRoute;
