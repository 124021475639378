import React from "react";

import View from "views";

import { ReactComponent as Logo } from "../../assets/svg/WISE_Logo.svg";

const AuthLayout = (props) => {
  return (
    <div className="app-layout-blank flex flex-auto flex-col h-[100vh] bg-white">
      <div className="grid lg:grid-cols-3 h-full">
        <div
          className="bg-no-repeat bg-cover py-16 px-14 flex-col justify-between hidden lg:flex xl:max-w-[500px] xl:min-w-[500px]"
          style={{
            backgroundImage: `url('/img/others/auth-side-bg.jpg')`,
          }}
        >
          <Logo />
        </div>
        <div className="col-span-2 flex flex-col justify-center items-center bg-white dark:bg-gray-800">
          <div className="xl:min-w-[450px] px-8">
            <View {...props} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
