export const NAV_ITEM_TYPE_TITLE = "title";
export const NAV_ITEM_TYPE_COLLAPSE = "collapse";
export const NAV_ITEM_TYPE_ITEM = "item";

export const NAVIGATION_ICONS = {
  DASHBOARD: "dashboard",
  ADMINS: "admins",
  USERS: "users",
  DISCIPLESHIP: "discipleship",
  USER_GROUPS: "user-groups",
  REGION: "region",
  DENOMINATION: "denomination",
  MINISTRY_MAP: "ministry-map",
  GET_TRAINED: "get-trained",
  REPORTS: "reports",
  NOTIFICATIONS: "notifications",
  SETTINGS: "settings",
};
