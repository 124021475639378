import i18n from "locales";
import BaseService from "./BaseService";
import { ToastService } from "services/ToastService";

const ApiService = {
  fetchData(param) {
    return new Promise((resolve, reject) => {
      BaseService(param)
        .then((response) => {
          resolve(response.data);
        })
        .catch((errors) => {
          reject(errors);
        });
    });
  },

  fetchDataWithError(param) {
    return new Promise((resolve, reject) => {
      BaseService(param)
        .then((response) => {
          resolve(response.data);
        })
        .catch((errors) => {
          reject(errors);
          ToastService.error(i18n.t("ALERTS.SOMETHING_WENT_WRONG"));
        });
    });
  },
};

export default ApiService;
