export const STATUSES = {
  SUCCESS: "success",
  FAILED: "failed",
};

export const STATE_TYPES_MAP = {
  SUCCESS: "success",
  SUCCESS_SECONDARY: "success-secondary",
  DANGER: "danger",
  INFO: "info",
  INFO_SECONDARY: "info-secondary",
  WARNING: "warning",
};
