import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavToggle } from "components/shared";

import withHeaderItem from "utils/hoc/withHeaderItem";
import useResponsive from "utils/hooks/useResponsive";
import { setSideNavCollapse } from "store/theme/themeSlice";

export const SideNavToggle = ({ className }) => {
  const sideNavCollapse = useSelector(
    ({ theme }) => theme.layout.sideNavCollapse,
  );
  const dispatch = useDispatch();

  const { larger } = useResponsive();

  const onCollapse = () => {
    dispatch(setSideNavCollapse(!sideNavCollapse));
  };

  return (
    <>
      {larger.md && (
        <div className={className} onClick={onCollapse}>
          <NavToggle className="text-2xl" toggled={sideNavCollapse} />
        </div>
      )}
    </>
  );
};

export default withHeaderItem(SideNavToggle);
