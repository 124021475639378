import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { setUser, initialState } from "store/auth/userSlice";
import {
  apiSignIn,
  apiSignIn2FA,
  apiSignOut,
  apiSignUp,
} from "services/AuthService";
import {
  onSignInSuccess,
  onSetSignInSuccess,
  onSignOutSuccess,
  setToken,
} from "store/auth/sessionSlice";
import { STATUSES } from "constants/statuses.constant";
import { REDIRECT_URL_KEY } from "constants/app.constant";
import { URLS } from "constants/links.constant";
import { getErrorMessage } from "utils/getErrorMessage";
import appConfig from "configs/app.config";
import useQuery from "./useQuery";

function useAuth() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const query = useQuery();

  const { token, signedIn } = useSelector(({ auth }) => auth.session);

  const { email } = useSelector(({ auth }) => auth.user);

  const signIn = async (values) => {
    try {
      const { data } = await apiSignIn(values);

      if (!data) return;

      const { token, user } = data;

      if (!user && !token) return;

      dispatch(setUser(user));

      if (user.twoFactorEnabled) {
        navigate(URLS.TWO_FA);
      } else {
        dispatch(onSignInSuccess(token));
        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
      }

      return {
        status: STATUSES.SUCCESS,
        message: "",
      };
    } catch (error) {
      return {
        status: STATUSES.FAILED,
        message: getErrorMessage(error),
      };
    }
  };

  const signIn2FA = async ({ code }) => {
    try {
      const { data } = await apiSignIn2FA({ token: code, email });

      if (!data) return;

      dispatch(setToken(data.token));
      dispatch(onSetSignInSuccess());

      return {
        status: STATUSES.SUCCESS,
        message: "",
      };
    } catch (error) {
      return {
        status: STATUSES.FAILED,
        message: getErrorMessage(error),
      };
    }
  };

  const signUp = async (values) => {
    try {
      const resp = await apiSignUp(values);
      if (resp.data) {
        const { token } = resp.data;
        dispatch(onSignInSuccess(token));
        if (resp.data.user) {
          dispatch(
            setUser(
              resp.data.user || {
                avatar: "",
                userName: "Anonymous",
                authority: ["USER"],
                email: "",
              },
            ),
          );
        }
        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
        return {
          status: "success",
          message: "",
        };
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const handleSignOut = () => {
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    navigate(appConfig.unAuthenticatedEntryPath);
  };

  const signOut = async () => {
    await apiSignOut();
    handleSignOut();
  };

  return {
    authenticated: token && signedIn,
    signIn,
    signIn2FA,
    signUp,
    signOut,
  };
}

export default useAuth;
