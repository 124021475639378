const appConfig = {
  apiPrefix: `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_API_VERSION}`,
  authenticatedEntryPath: "/dashboard",
  unAuthenticatedEntryPath: "/sign-in",
  tourPath: "/",
  locale: "en",
  enableMock: false,
};

export default appConfig;
