import { createSlice } from "@reduxjs/toolkit";

import { userInitialState } from "constants/user.constant";

export const initialState = { ...userInitialState };

export const userSlice = createSlice({
  name: "auth/user",
  initialState,
  reducers: {
    setUser: (_, { payload }) => payload,
    userLoggedOut: () => initialState,
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
