import {
  NAV_ITEM_TYPE_ITEM,
  NAVIGATION_ICONS,
} from "constants/navigation.constant";
import { AUTHORITY } from "constants/roles.constant";

const { SUPER_ADMIN, ADMIN } = AUTHORITY;

const {
  DASHBOARD,
  ADMINS: ADMINS_ICON,
  USERS,
  DISCIPLESHIP,
  USER_GROUPS,
  REGION,
  DENOMINATION,
  MINISTRY_MAP,
  GET_TRAINED,
  REPORTS,
  NOTIFICATIONS,
  SETTINGS,
} = NAVIGATION_ICONS;

const navigationConfig = [
  {
    key: "dashboard",
    path: "/dashboard",
    title: "SIDE_MENU.DASHBOARD",
    translateKey: "SIDE_MENU.DASHBOARD",
    icon: DASHBOARD,
    type: NAV_ITEM_TYPE_ITEM,
    authority: [SUPER_ADMIN, ADMIN],
    subMenu: [],
  },
  {
    key: "admins",
    path: "/admins",
    title: "SIDE_MENU.ADMINS",
    translateKey: "SIDE_MENU.ADMINS",
    icon: ADMINS_ICON,
    type: NAV_ITEM_TYPE_ITEM,
    authority: [SUPER_ADMIN],
    subMenu: [],
  },
  {
    key: "users",
    path: "/users",
    title: "SIDE_MENU.USERS",
    translateKey: "SIDE_MENU.USERS",
    icon: USERS,
    type: NAV_ITEM_TYPE_ITEM,
    authority: [SUPER_ADMIN, ADMIN],
    subMenu: [],
  },
  {
    key: "discipleship",
    path: "/discipleship",
    title: "SIDE_MENU.DISCIPLESHIP",
    translateKey: "SIDE_MENU.DISCIPLESHIP",
    icon: DISCIPLESHIP,
    type: NAV_ITEM_TYPE_ITEM,
    authority: [SUPER_ADMIN, ADMIN],
    subMenu: [],
  },
  {
    key: "userGroups",
    path: "/user-groups",
    title: "SIDE_MENU.USER_GROUPS",
    translateKey: "SIDE_MENU.USER_GROUPS",
    icon: USER_GROUPS,
    type: NAV_ITEM_TYPE_ITEM,
    authority: [SUPER_ADMIN, ADMIN],
    subMenu: [],
  },
  {
    key: "region",
    path: "/region",
    title: "SIDE_MENU.REGION",
    translateKey: "SIDE_MENU.REGION",
    icon: REGION,
    type: NAV_ITEM_TYPE_ITEM,
    authority: [SUPER_ADMIN, ADMIN],
    subMenu: [],
  },
  {
    key: "denomination",
    path: "/denomination",
    title: "SIDE_MENU.DENOMINATION",
    translateKey: "SIDE_MENU.DENOMINATION",
    icon: DENOMINATION,
    type: NAV_ITEM_TYPE_ITEM,
    authority: [SUPER_ADMIN, ADMIN],
    subMenu: [],
  },
  {
    key: "ministryMap",
    path: "/ministry-map",
    title: "SIDE_MENU.MINISTRY_MAP",
    translateKey: "SIDE_MENU.MINISTRY_MAP",
    icon: MINISTRY_MAP,
    type: NAV_ITEM_TYPE_ITEM,
    authority: [SUPER_ADMIN, ADMIN],
    subMenu: [],
  },
  {
    key: "getTrained",
    path: "/get-trained",
    title: "SIDE_MENU.GET_TRAINED",
    translateKey: "SIDE_MENU.GET_TRAINED",
    icon: GET_TRAINED,
    type: NAV_ITEM_TYPE_ITEM,
    authority: [SUPER_ADMIN, ADMIN],
    subMenu: [],
  },
  {
    key: "reports",
    path: "/reports",
    title: "SIDE_MENU.REPORTS",
    translateKey: "SIDE_MENU.REPORTS",
    icon: REPORTS,
    type: NAV_ITEM_TYPE_ITEM,
    authority: [SUPER_ADMIN, ADMIN],
    subMenu: [],
  },
  {
    key: "notifications",
    path: "/notifications",
    title: "SIDE_MENU.NOTIFICATIONS",
    translateKey: "SIDE_MENU.NOTIFICATIONS",
    icon: NOTIFICATIONS,
    type: NAV_ITEM_TYPE_ITEM,
    authority: [SUPER_ADMIN, ADMIN],
    subMenu: [],
  },
  {
    key: "settings",
    path: "/settings",
    title: "SIDE_MENU.SETTINGS",
    translateKey: "SIDE_MENU.SETTINGS",
    icon: SETTINGS,
    type: NAV_ITEM_TYPE_ITEM,
    authority: [SUPER_ADMIN, ADMIN],
    subMenu: [],
  },
];

export default navigationConfig;
