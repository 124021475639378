import React from "react";
import {
  TbLayoutDashboard,
  TbUser,
  TbUsers,
  TbLayoutList,
} from "react-icons/tb";
import {
  RiCollageLine,
  RiNotification2Line,
  RiSettings2Line,
} from "react-icons/ri";
import { GiChurch } from "react-icons/gi";
import { TfiLocationPin } from "react-icons/tfi";
import { FiPlayCircle } from "react-icons/fi";
import { RiShieldUserLine, RiCloseFill } from "react-icons/ri";

import { NAVIGATION_ICONS } from "constants/navigation.constant";

const {
  DASHBOARD,
  ADMINS,
  USERS,
  DISCIPLESHIP,
  USER_GROUPS,
  REGION,
  DENOMINATION,
  MINISTRY_MAP,
  GET_TRAINED,
  REPORTS,
  NOTIFICATIONS,
  SETTINGS,
} = NAVIGATION_ICONS;

const navigationIcon = {
  [DASHBOARD]: <TbLayoutDashboard />,
  [ADMINS]: <RiShieldUserLine />,
  [USERS]: <TbUser />,
  [DISCIPLESHIP]: <TbLayoutList />,
  [USER_GROUPS]: <TbUsers />,
  [REGION]: <RiCollageLine />,
  [DENOMINATION]: <GiChurch />,
  [MINISTRY_MAP]: <TfiLocationPin />,
  [GET_TRAINED]: <FiPlayCircle />,
  [REPORTS]: <RiCloseFill />,
  [NOTIFICATIONS]: <RiNotification2Line />,
  [SETTINGS]: <RiSettings2Line />,
};

export default navigationIcon;
