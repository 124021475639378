import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import VerticalSingleMenuItem from "./VerticalSingleMenuItem";
import VerticalCollapsedMenuItem from "./VerticalCollapsedMenuItem";

import { Menu } from "components/ui";
import { AuthorityCheck } from "components/shared";

import { themeConfig } from "configs/theme.config";
import {
  NAV_ITEM_TYPE_TITLE,
  NAV_ITEM_TYPE_COLLAPSE,
  NAV_ITEM_TYPE_ITEM,
} from "constants/navigation.constant";
import { NAV_MODE_ARRAY } from "constants/theme.constant";

import useMenuActive from "utils/hooks/useMenuActive";

const { MenuGroup } = Menu;

const VerticalMenuContent = (props) => {
  const {
    navMode = themeConfig.navMode,
    collapsed,
    routeKey,
    navigationTree = [],
    userAuthority = [],
    onMenuItemClick,
    permissions,
    direction = themeConfig.direction,
  } = props;

  const { t } = useTranslation();

  const [defaultExpandKey, setDefaultExpandKey] = useState([]);

  const { activedRoute } = useMenuActive(navigationTree, routeKey);

  useEffect(() => {
    if (defaultExpandKey.length === 0 && activedRoute?.parentKey) {
      setDefaultExpandKey([activedRoute?.parentKey]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activedRoute?.parentKey]);

  const handleLinkClick = () => {
    onMenuItemClick?.();
  };

  const getNavItem = (nav) => {
    if (nav.subMenu.length === 0 && nav.type === NAV_ITEM_TYPE_ITEM) {
      return (
        <VerticalSingleMenuItem
          key={nav.key}
          nav={nav}
          onLinkClick={handleLinkClick}
          sideCollapsed={collapsed}
          userAuthority={userAuthority}
          permissions={permissions}
          direction={direction}
        />
      );
    }

    if (nav.subMenu.length > 0 && nav.type === NAV_ITEM_TYPE_COLLAPSE) {
      return (
        <VerticalCollapsedMenuItem
          key={nav.key}
          nav={nav}
          onLinkClick={onMenuItemClick}
          sideCollapsed={collapsed}
          userAuthority={userAuthority}
          direction={direction}
        />
      );
    }

    if (nav.type === NAV_ITEM_TYPE_TITLE) {
      if (nav.subMenu.length > 0) {
        return (
          <AuthorityCheck
            key={nav.key}
            userAuthority={userAuthority}
            authority={nav.authority}
          >
            <MenuGroup label={t(nav.translateKey) || nav.title}>
              {nav.subMenu.map((subNav) =>
                subNav.subMenu.length > 0 ? (
                  <VerticalCollapsedMenuItem
                    key={subNav.key}
                    nav={subNav}
                    onLinkClick={onMenuItemClick}
                    sideCollapsed={collapsed}
                    userAuthority={userAuthority}
                    direction={direction}
                  />
                ) : (
                  <VerticalSingleMenuItem
                    key={subNav.key}
                    nav={subNav}
                    onLinkClick={onMenuItemClick}
                    sideCollapsed={collapsed}
                    userAuthority={userAuthority}
                    permissions={permissions}
                    direction={direction}
                  />
                ),
              )}
            </MenuGroup>
          </AuthorityCheck>
        );
      } else {
        <MenuGroup label={nav.title} />;
      }
    }
  };

  return (
    <Menu
      className="px-4 pb-4"
      variant={navMode}
      sideCollapsed={collapsed}
      defaultActiveKeys={activedRoute?.key ? [activedRoute.key] : []}
      defaultExpandedKeys={defaultExpandKey}
    >
      {navigationTree.map((nav) => getNavItem(nav))}
    </Menu>
  );
};

VerticalMenuContent.propTypes = {
  navMode: PropTypes.oneOf(NAV_MODE_ARRAY),
  collapsed: PropTypes.bool,
  routeKey: PropTypes.string,
  navigationTree: PropTypes.array,
  userAuthority: PropTypes.array,
  direction: PropTypes.oneOf(["rtl", "ltr"]),
};

export default VerticalMenuContent;
