import React, { useState, Suspense, lazy } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";

import { Drawer } from "components/ui";
import { NavToggle } from "components/shared";

import {
  NAV_MODE_THEMED,
  NAV_MODE_TRANSPARENT,
  DIR_RTL,
} from "constants/theme.constant";
import withHeaderItem from "utils/hoc/withHeaderItem";
import navigationConfig from "configs/navigation.config";
import useResponsive from "utils/hooks/useResponsive";

const VerticalMenuContent = lazy(() =>
  import("components/VerticalMenuContent"),
);

const MobileNavToggle = withHeaderItem(NavToggle);

const MobileNav = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openDrawer = () => setIsOpen(true);

  const onDrawerClose = () => setIsOpen(false);

  const themeColor = useSelector(({ theme }) => theme.themeColor);
  const primaryColorLevel = useSelector(({ theme }) => theme.primaryColorLevel);
  const navMode = useSelector(({ theme }) => theme.navMode);
  const mode = useSelector(({ theme }) => theme.mode);
  const direction = useSelector(({ theme }) => theme.direction);
  const sideNavCollapse = useSelector(
    ({ theme }) => theme.layout.sideNavCollapse,
  );

  const currentRouteKey = useSelector(
    ({ base }) => base.common.currentRouteKey,
  );

  const userAuthority = useSelector(({ auth }) => [auth.user.role]);

  const { smaller } = useResponsive();

  const navColor = () => {
    if (navMode === NAV_MODE_THEMED) {
      return `bg-${themeColor}-${primaryColorLevel} side-nav-${navMode}`;
    }

    if (navMode === NAV_MODE_TRANSPARENT) {
      return `side-nav-${mode}`;
    }

    return `side-nav-${navMode}`;
  };

  return (
    <>
      {smaller.md && (
        <>
          <div className="text-2xl" onClick={openDrawer}>
            <MobileNavToggle toggled={isOpen} />
          </div>
          <Drawer
            title="Navigation"
            isOpen={isOpen}
            onClose={onDrawerClose}
            onRequestClose={onDrawerClose}
            bodyClass={classNames(navColor(), "p-0")}
            width={330}
            placement={direction === DIR_RTL ? "right" : "left"}
          >
            <Suspense fallback={<></>}>
              {isOpen && (
                <VerticalMenuContent
                  navMode={navMode}
                  collapsed={sideNavCollapse}
                  navigationTree={navigationConfig}
                  routeKey={currentRouteKey}
                  userAuthority={userAuthority}
                  onMenuItemClick={onDrawerClose}
                  direction={direction}
                />
              )}
            </Suspense>
          </Drawer>
        </>
      )}
    </>
  );
};

export default MobileNav;
