export const URLS = {
  SIGN_IN: "/sign-in",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  PRIVACY_POLICY: "/privacy-policy",
  TERMS_AND_CONDITIONS: "/terms-and-conditions",
  TWO_FA: "/2fa",
  LIMIT_EXCEEDED: "/reset-password-limit-exceeded",
  EXPIRED: "/reset-password-expired",
  ADMIN_EXPIRED: "/create-password-admin-expired",
  USERS: "/users",
  USER_DETAILS: "/users/user-details",
  USER_GROUPS: "/user-groups",
  GROUP_DETAILS: "/user-groups/group-details",
  GET_TRAINED: "/get-trained",
  GET_TRAINED_DETAILS: "/get-trained/video-category-details",
  NOTIFICATIONS: "/notifications",
  NOTIFICATION_DETAILS: "/notifications/details",
  CHANGE_EMAIL_EXPIRED: "/change-email-expired",
  CHANGE_EMAIL: "/change-email",
  CHANGE_PASSWORD_EXPIRED: "/change-password-expired",
  CHANGE_PASSWORD: "/change-password",
};
