import React from "react";
import PropTypes from "prop-types";

import {
  HiCheckCircle,
  HiInformationCircle,
  HiExclamation,
  HiXCircle,
  HiOutlineCheckCircle,
} from "react-icons/hi";
import { AiOutlineInfoCircle } from "react-icons/ai";

import { STATE_TYPES_MAP } from "constants/statuses.constant";

const { SUCCESS, SUCCESS_SECONDARY, DANGER, INFO, INFO_SECONDARY, WARNING } =
  STATE_TYPES_MAP;

const ICONS = {
  [SUCCESS]: {
    color: "text-emerald-400",
    icon: <HiCheckCircle />,
  },
  [SUCCESS_SECONDARY]: {
    color: "text-green-500",
    icon: <HiOutlineCheckCircle />,
  },
  [INFO]: {
    color: "text-blue-400",
    icon: <HiInformationCircle />,
  },
  [INFO_SECONDARY]: {
    color: "text-indigo-600",
    icon: <AiOutlineInfoCircle />,
  },
  [WARNING]: {
    color: "text-yellow-400",
    icon: <HiExclamation />,
  },
  [DANGER]: {
    color: "text-red-400",
    icon: <HiXCircle />,
  },
};

const StatusIcon = (props) => {
  const { type, custom, iconColor } = props;

  const icon = ICONS[type];

  return (
    <span className={`text-2xl ${iconColor || icon.color}`}>
      {custom || icon.icon}
    </span>
  );
};

StatusIcon.defaultProps = {
  type: "info",
};

StatusIcon.propTypes = {
  type: PropTypes.oneOf(Object.values(STATE_TYPES_MAP)),
  custom: PropTypes.node,
};

export default StatusIcon;
