import React from "react";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

import { Menu, Tooltip } from "components/ui";
import { AuthorityCheck } from "components/shared";

import VerticalMenuIcon from "./VerticalMenuIcon";

import useAuthority from "utils/hooks/useAuthority";
import { usePermission } from "utils/hooks/usePermission";

const { MenuItem } = Menu;

const CollapsedItem = ({ title, translateKey, children, direction }) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      title={t(translateKey) || title}
      placement={direction === "rtl" ? "left" : "right"}
    >
      {children}
    </Tooltip>
  );
};

const DefaultItem = ({
  nav,
  onLinkClick,
  sideCollapsed,
  userAuthority,
  permissions,
}) => {
  const roleMatched = useAuthority(userAuthority, nav.authority);

  const isAvailablePermission = usePermission(permissions, nav.title);

  if (!roleMatched) return null;
  if (permissions && !isAvailablePermission) return null;

  return (
    <AuthorityCheck userAuthority={userAuthority} authority={nav.authority}>
      <MenuItem key={nav.key} eventKey={nav.key} className="mb-2">
        <Link
          to={nav.path}
          onClick={() =>
            onLinkClick?.({
              key: nav.key,
              title: nav.title,
              path: nav.path,
            })
          }
          className="flex items-center h-full w-full"
        >
          <VerticalMenuIcon icon={nav.icon} />
          {!sideCollapsed && (
            <span>
              <Trans i18nKey={nav.translateKey} defaults={nav.title} />
            </span>
          )}
        </Link>
      </MenuItem>
    </AuthorityCheck>
  );
};

const VerticalSingleMenuItem = ({
  nav,
  onLinkClick,
  sideCollapsed,
  userAuthority,
  permissions,
  direction,
}) => {
  return (
    <AuthorityCheck userAuthority={userAuthority} authority={nav.authority}>
      {sideCollapsed ? (
        <CollapsedItem
          title={nav.title}
          translateKey={nav.translateKey}
          direction={direction}
        >
          <DefaultItem
            nav={nav}
            sideCollapsed={sideCollapsed}
            onLinkClick={onLinkClick}
            userAuthority={userAuthority}
            permissions={permissions}
          />
        </CollapsedItem>
      ) : (
        <DefaultItem
          nav={nav}
          sideCollapsed={sideCollapsed}
          onLinkClick={onLinkClick}
          userAuthority={userAuthority}
          permissions={permissions}
        />
      )}
    </AuthorityCheck>
  );
};

export default VerticalSingleMenuItem;
