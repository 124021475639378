import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import appConfig from "configs/app.config";
import useAuthority from "utils/hooks/useAuthority";
import { usePermission } from "utils/hooks/usePermission";

const AuthorityGuard = ({
  children,
  userAuthority = [],
  authority = [],
  title,
}) => {
  const adminPermissions = useSelector(
    ({ auth }) => auth.user.adminPermissions,
  );

  const roleMatched = useAuthority(userAuthority, authority);

  const isAvailablePermission = usePermission(adminPermissions, title);

  return roleMatched && isAvailablePermission ? (
    children
  ) : (
    <Navigate to={appConfig.authenticatedEntryPath} />
  );
};

export default AuthorityGuard;
