import React, { useEffect, useCallback } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";

import { LocalStorageService } from "services/LocalStorageService";
import appConfig from "configs/app.config";
import useAuth from "utils/hooks/useAuth";
import useQuery from "utils/hooks/useQuery";
import { TOKEN_URL_KEY } from "constants/app.constant";

const { authenticatedEntryPath } = appConfig;

const PublicRoute = () => {
  const { authenticated, signOut } = useAuth();
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  const query = useQuery();
  const token = query.get(TOKEN_URL_KEY);

  const logoutWithRedirect = useCallback(async () => {
    try {
      await signOut();
    } catch {
      LocalStorageService.clear();
    }
    navigate(`${pathname}${search}`);
  }, [pathname, search, navigate]);

  useEffect(() => {
    if (authenticated && token) {
      logoutWithRedirect();
      return;
    }
  }, [authenticated, token, pathname, logoutWithRedirect]);

  return authenticated ? <Navigate to={authenticatedEntryPath} /> : <Outlet />;
};

export default PublicRoute;
