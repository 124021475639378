import ApiService from "./ApiService";

export async function apiSignIn(data) {
  return ApiService.fetchData({
    url: "/admin-sessions",
    method: "post",
    data,
  });
}

export async function apiSignIn2FA(data) {
  return ApiService.fetchData({
    url: "/admin-sessions/code",
    method: "post",
    data,
  });
}

export async function apiSignUp(data) {
  return ApiService.fetchData({
    url: "/sign-up",
    method: "post",
    data,
  });
}

export async function apiSignOut(data) {
  return ApiService.fetchData({
    url: "/admin-sessions",
    method: "delete",
    data,
  });
}

export async function apiForgotPassword(data) {
  return ApiService.fetchData({
    url: "/admin-verifications/password",
    method: "post",
    data,
  });
}

export async function apiResetPassword(data) {
  return ApiService.fetchData({
    url: "/admin-verifications/password",
    method: "put",
    data,
  });
}

export async function apiCheckToken(data) {
  return ApiService.fetchData({
    url: "/admin-verifications/token",
    method: "post",
    data,
  });
}

export async function apiCreateAdminPassword(data) {
  return ApiService.fetchData({
    url: "/admins/set-password",
    method: "put",
    data,
  });
}

export async function apiCheckAdminToken(data) {
  return ApiService.fetchData({
    url: "/admins/token",
    method: "post",
    data,
  });
}
