import React from "react";

import View from "views";

const BlankLayout = (props) => {
  return (
    <div className="app-layout-blank flex flex-auto flex-col items-center h-[100vh] bg-neutral-100">
      <View {...props} />
    </div>
  );
};

export default BlankLayout;
