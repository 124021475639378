import React, { useState, useEffect, useCallback, useMemo } from "react";
import classNames from "classnames";

import {
  TABLE_START_PAGE,
  PAGINATION_PAGER_COUNT,
  PAGINATION_DOUBLE_ARROW_STEP,
  PAGINATION_MIDDLE_PAGER_COUNT,
} from "constants/table.constant";

import {
  HiOutlineChevronDoubleLeft,
  HiOutlineDotsHorizontal,
  HiChevronDoubleRight,
} from "react-icons/hi";

const ARROW_CLICK_EVENTS = {
  NEXT: "nextMore",
  PREV: "prevMore",
};
const { NEXT, PREV } = ARROW_CLICK_EVENTS;

const NextMore = ({ className, onArrow }) => {
  const [quickNextArrowIcon, setQuickNextArrowIcon] = useState(false);

  return (
    <li
      className={className}
      onMouseEnter={() => {
        setQuickNextArrowIcon(true);
      }}
      onMouseLeave={() => {
        setQuickNextArrowIcon(false);
      }}
      onClick={() => onArrow(NEXT)}
    >
      {quickNextArrowIcon ? (
        <HiChevronDoubleRight />
      ) : (
        <HiOutlineDotsHorizontal />
      )}
    </li>
  );
};

const PrevMore = ({ className, onArrow }) => {
  const [quickPrevArrowIcon, setQuickPrevArrowIcon] = useState(false);

  return (
    <li
      className={className}
      onMouseEnter={() => {
        setQuickPrevArrowIcon(true);
      }}
      onMouseLeave={() => {
        setQuickPrevArrowIcon(false);
      }}
      onClick={() => onArrow(PREV)}
    >
      {quickPrevArrowIcon ? (
        <HiOutlineChevronDoubleLeft />
      ) : (
        <HiOutlineDotsHorizontal />
      )}
    </li>
  );
};

const Pagers = (props) => {
  const { pageCount, currentPage, onChange, pagerClass } = props;

  const [showPrevMore, setShowPrevMore] = useState(false);
  const [showNextMore, setShowNextMore] = useState(false);

  useEffect(() => {
    if (pageCount > PAGINATION_PAGER_COUNT) {
      if (currentPage > PAGINATION_MIDDLE_PAGER_COUNT) {
        setShowPrevMore(true);
      } else {
        setShowPrevMore(false);
      }

      if (currentPage <= pageCount - PAGINATION_MIDDLE_PAGER_COUNT) {
        setShowNextMore(true);
      } else {
        setShowNextMore(false);
      }
    } else {
      setShowPrevMore(false);
      setShowNextMore(false);
    }
  }, [currentPage, pageCount]);

  const onPagerClick = (value, e) => {
    e.preventDefault();
    let newPage = value;

    if (newPage === currentPage) return;

    if (newPage < TABLE_START_PAGE) {
      newPage = TABLE_START_PAGE;
    }
    if (newPage > pageCount) {
      newPage = pageCount;
    }

    onChange(newPage);
  };

  const onArrowClick = useCallback(
    (e) => {
      let newPage = currentPage;
      if (e === NEXT) {
        newPage = currentPage + PAGINATION_DOUBLE_ARROW_STEP;
      }
      if (e === PREV) {
        newPage = currentPage - PAGINATION_DOUBLE_ARROW_STEP;
      }
      onChange(newPage);
    },
    [currentPage, onChange],
  );

  const getPages = useMemo(() => {
    const pagerArray = [];

    if (showPrevMore && !showNextMore) {
      const startPage = pageCount - PAGINATION_MIDDLE_PAGER_COUNT;
      for (let i = startPage; i < pageCount; i++) {
        pagerArray.push(i);
      }
    } else if (!showPrevMore && showNextMore) {
      for (
        let i = PAGINATION_DOUBLE_ARROW_STEP;
        i < PAGINATION_PAGER_COUNT;
        i++
      ) {
        pagerArray.push(i);
      }
    } else if (showPrevMore && showNextMore) {
      const offset =
        Math.floor(PAGINATION_PAGER_COUNT / PAGINATION_DOUBLE_ARROW_STEP) - 1;
      const maxRange =
        currentPage >= pageCount - PAGINATION_DOUBLE_ARROW_STEP &&
        currentPage <= pageCount;
      for (
        let i = currentPage - offset;
        i <= currentPage + (maxRange ? 0 : offset);
        i++
      ) {
        pagerArray.push(i);
      }
    } else {
      for (let i = PAGINATION_DOUBLE_ARROW_STEP; i < pageCount; i++) {
        pagerArray.push(i);
      }
    }
    if (pagerArray.length > PAGINATION_MIDDLE_PAGER_COUNT) {
      return [];
    }

    return pagerArray;
  }, [showPrevMore, showNextMore, currentPage, pageCount]);

  const getPagerClass = (index) => {
    return classNames(
      pagerClass.default,
      currentPage === index ? pagerClass.active : pagerClass.inactive,
    );
  };

  return (
    <ul>
      {pageCount > 0 && (
        <li
          className={getPagerClass(TABLE_START_PAGE)}
          onClick={(e) => onPagerClick(TABLE_START_PAGE, e)}
          tabIndex="0"
        >
          {TABLE_START_PAGE}
        </li>
      )}
      {showPrevMore && (
        <PrevMore
          onArrow={(arrow) => onArrowClick(arrow)}
          className={classNames(pagerClass.default, pagerClass.inactive)}
        />
      )}
      {getPages.map((pager, index) => {
        return (
          <li
            key={index}
            className={getPagerClass(pager)}
            onClick={(e) => onPagerClick(pager, e)}
            tabIndex="0"
          >
            {pager}
          </li>
        );
      })}
      {showNextMore && (
        <NextMore
          onArrow={(arrow) => onArrowClick(arrow)}
          className={classNames(pagerClass.default, pagerClass.inactive)}
        />
      )}
      {pageCount > 1 && (
        <li
          className={getPagerClass(pageCount)}
          onClick={(e) => onPagerClick(pageCount, e)}
          tabIndex="0"
        >
          {pageCount}
        </li>
      )}
    </ul>
  );
};

export default Pagers;
