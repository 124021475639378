import React, { memo } from "react";
import { useLocation } from "react-router-dom";

import BlankLayout from "./BlankLayout";
import AppLayout from "./AppLayout";
import AuthLayout from "./AuthLayout";

import useAuth from "utils/hooks/useAuth";
import useDirection from "utils/hooks/useDirection";
import useLocale from "utils/hooks/useLocale";
import { URLS } from "constants/links.constant";

const { PRIVACY_POLICY, TERMS_AND_CONDITIONS } = URLS;

const Layout = () => {
  const { authenticated } = useAuth();
  const { pathname } = useLocation();

  useDirection();

  useLocale();

  if (pathname === PRIVACY_POLICY || pathname === TERMS_AND_CONDITIONS) {
    return <BlankLayout />;
  }
  if (authenticated) {
    return <AppLayout />;
  }

  return <AuthLayout />;
};

export default memo(Layout);
