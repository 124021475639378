export const ERROR_SHOW_TIME = 5000; //5sec

export const IMAGE_ALLOWED_FORMATS = ["image/jpeg", "image/jpg", "image/png"];

export const MAX_IMAGE_SIZE = 5 * 1024 * 1024; // 5mb

export const ONLY_ONE_IMAGE_COUNT = 1;

export const FILE_TYPES = {
  AVATAR: 0,
  VIDEO_PREVIEW: 1,
  GROUP_IMAGE: 2,
};

export const MIME_TYPES = {
  CSV: "text/csv",
};
