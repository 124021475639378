export const userInitialState = {
  id: 0,
  avatar: null,
  firstName: null,
  lastName: null,
  email: "",
  country: null,
  isBlocked: null,
  languageCode: null,
  phoneNumber: null,
  role: "",
  adminPermissions: null,
};

export const USER_DETAILS_INITIAL_STATE = {
  firstName: "",
  lastName: "",
  avatar: {},
  status: "",
  country: "",
  joinedAt: "",
  denomination: {
    id: 0,
    name: "",
  },
  groups: [],
  email: "",
  phoneNumber: "",
  id: 0,
};

export const PROFILE_INITIAL_STATE = {
  id: 0,
  email: "",
  twoFactorEnabled: undefined,
};

export const USER_STATUSES = {
  ACTIVE: "active",
  BLOCKED: "blocked",
};

export const ADMIN_STATUSES = {
  ...USER_STATUSES,
  PENDING: "pending",
};

const { ACTIVE, BLOCKED } = USER_STATUSES;
const { PENDING } = ADMIN_STATUSES;

export const USER_STATUSES_COLORS_NUM = {
  [ACTIVE]: "emerald",
  [BLOCKED]: "red",
};

export const ADMIN_STATUSES_COLORS_NUM = {
  ...USER_STATUSES_COLORS_NUM,
  [PENDING]: "yellow",
};

export const GENERAL_PERMISSIONS = [
  "SIDE_MENU.DASHBOARD",
  "SIDE_MENU.SETTINGS",
];

export const PERMISSIONS = [
  { value: 1, label: "SIDE_MENU.USERS" },
  { value: 2, label: "SIDE_MENU.DISCIPLESHIP" },
  { value: 4, label: "SIDE_MENU.USER_GROUPS" },
  { value: 8, label: "SIDE_MENU.REGION" },
  { value: 16, label: "SIDE_MENU.DENOMINATION" },
  { value: 32, label: "SIDE_MENU.MINISTRY_MAP" },
  { value: 64, label: "SIDE_MENU.GET_TRAINED" },
  { value: 128, label: "SIDE_MENU.NOTIFICATIONS" },
  { value: 256, label: "SIDE_MENU.REPORTS" },
];
